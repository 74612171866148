import Slider from 'react-slick';
import Grid from 'antd/lib/grid';
import classNames from 'classnames';

import { IGame } from 'src/interface';

import Button from '../Button';
import ImageC from '../Image';
import Skeleton from '../Skeleton';

import classes from './index.module.less';

interface Props {
	className?: string;
	items: IGame[];
	isLoading: boolean;
}

const CardGame = ({ item, isLoading }: { item: IGame; isLoading: boolean }) => {
	if (isLoading) {
		return <Skeleton isLoading={isLoading} className={classes['card-game']} />;
	}

	return (
		<div className={classNames(classes['card-game'])}>
			<div className={classes['content-wrapper']}>
				<ImageC
					priority
					alt={`banner ${item?.name}`}
					className={classes.image}
					src={item?.main_image_url}
				/>
				<div className={classNames(classes['button-group'], 'fadeIn')}>
					<a className="w-100" href={`/games/${item?.slug}`}>
						<Button className={classes.button}>MORE INFO</Button>
					</a>
					<a className="w-100" href={`/store/${item?.slug}`}>
						<Button className={classes.button}>GAME STORE</Button>
					</a>
				</div>
			</div>
		</div>
	);
};

const CarouselGames = ({ className = '', items, isLoading }: Props) => {
	const breakpoint = Grid.useBreakpoint();
	const { sm, md, lg, xl } = breakpoint;

	let slidesToShow = 5;
	if (xl) {
		slidesToShow = 5;
	} else if (md || lg) {
		slidesToShow = 4;
	} else if (sm) {
		slidesToShow = 2;
	} else {
		slidesToShow = 1;
	}

	return (
		<div className={classNames(classes['slider-container'], className)}>
			<Slider
				key={JSON.stringify(items ?? {})}
				className={classes.slider}
				infinite
				adaptiveHeight={true}
				centerMode={true}
				centerPadding="13%"
				slidesToShow={slidesToShow}
				// slidesToScroll={slidesToShow}
				slidesToScroll={1}
			>
				{(isLoading ? new Array(slidesToShow).fill({ id: 'loading' }) : items)?.map(
					(item: IGame) => (
						<div key={item?.id} className={classes['slide-container']}>
							<CardGame item={item} isLoading={isLoading} />
						</div>
					),
				)}
			</Slider>
		</div>
	);
};

export default CarouselGames;
