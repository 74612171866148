import { dehydrate, QueryClient } from 'react-query';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';

import global from 'src/constants/global';
import Home from 'src/containers/Home';
import { queryKeyBannerList, queryKeyGameList } from 'src/hooks';
import { wrapper } from 'src/redux/store';
import { fetchAPI } from 'src/utils/fetch-api';

const HomePage = () => {
	return (
		<>
			<NextSeo title="CREOPLAY | HOME" />
			<Home />
		</>
	);
};

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(() => async () => {
	const queryClient = new QueryClient();

	let notFound = false;

	try {
		await queryClient.prefetchQuery([queryKeyBannerList, null], () =>
			fetchAPI({
				endpoint: '/home/banners',
			}),
		);

		const params = { page: 1, limit: 10 };
		await queryClient.prefetchQuery([queryKeyGameList, params], () =>
			fetchAPI({
				endpoint: '/home/games',
				params: params,
			}),
		);
	} catch (err: any) {
		if (err?.status === 400) {
			return {
				notFound: true,
			};
		} else {
			throw err;
		}
	}

	if (notFound) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
		},
		revalidate: global.REVALIDATE_EXP,
	};
});

export default HomePage;
